import React, { useState } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, Testimonial, News, LoanWidget, Disclaimer } from '../components'
import { container, padding, boxShadow } from '../styles/global'
import { parseACF } from '../utils'
import * as Shared from '../styles/shared'

import tickIcon from '../assets/icons/tick-icon.svg'
import clockIcon from '../assets/icons/clock-icon.svg'

const Loans = props => {
    const data =
        props.previewData ||
        parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log('data', data)

    const breadcrumbs = {
        heading: {
            title: 'Borrow with Strive',
            link: '/business-loans/'
        },
        description: {
            title: 'Business Loans',
            link: undefined
        }
    }

    const renderIntro = () => {
        return (
            <Intro>
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.intro_intro_text,
                    }}
                />
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.intro_paragraph_text,
                    }}
                />
            </Intro>
        )
    }

    const renderFeatures = () => {
        const features = data.features_items.map((item, i) => {
            return (
                <Feature key={i}>
                    <Image key={i} src={tickIcon}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: item.item,
                        }}
                    />
                </Feature>
            )
        })

        return (
            <Features>
                <BGColor className={`bg-color`} />
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.features_heading,
                    }}
                />
                <Grid>{features}</Grid>
            </Features>
        )
    }

    const renderIntroduction = () => {
        return (
            <Wrapper>
                <Introduction>
                    <Grid>
                        <Left>
                            {renderIntro()}
                            {renderFeatures()}
                        </Left>
                        <Right>
                            <LoanWidget size="mini" />
                        </Right>
                    </Grid>
                </Introduction>
            </Wrapper>
        )
    }

    const renderProductFeatures = features => {
        return features.map((item, i) => {
            return (
                <ProductFeature key={i}>
                    <Image key={item.icon.id} src={item.icon.url}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                    {item.title}
                </ProductFeature>
            )
        })
    }

    const renderProducts = () => {
        const products = data.products.map((item, i) => {
            return (
                <Product key={i}>
                    <Grid>
                        <Left
                            onClick={e => {
                                e.preventDefault()
                                navigate(`/loans/${item.post.post_name}/`)
                            }}
                        >
                            <Info>
                                <Image key={item.post.id} src={clockIcon}>
                                    {(src, loading) => {
                                        return (
                                            <LoadedImage
                                                src={src}
                                                style={{
                                                    opacity: loading ? 0 : 1,
                                                }}
                                            />
                                        )
                                    }}
                                </Image>
                                {item.i_need}
                            </Info>

                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: item.post.post_title,
                                }}
                            />
                            <Grid>
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: item.tagline,
                                    }}
                                />
                                <ProductLink
                                    to={`/loans/${item.post.post_name}/`}
                                >
                                    View more >
                                </ProductLink>
                            </Grid>
                        </Left>
                        <Right>
                            <ProductFeatures>
                                {renderProductFeatures(item.features)}
                            </ProductFeatures>
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: item.short_description,
                                }}
                            />
                        </Right>
                    </Grid>
                </Product>
            )
        })
        return (
            <Products>
                <Container>{products}</Container>
            </Products>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            {renderIntroduction()}
            {renderProducts()}

            {data.testimonials && <Testimonial testimonials={data.testimonials} category={`borrower`} />}
            <Container>
                <LoanWidget />
            </Container>
            {data.news_posts && <News news_posts={data.news_posts} />}
            {data.show_disclaimer_text && (
                <Disclaimer disclaimer_text={data.disclaimer_text}/>
            )}
        </Layout>
    )
}

// Shared Child Components

const Heading = Shared.Heading
const Subheading = Shared.Subheading
const Description = Shared.Description
const Info = Shared.Info
const BGColor = Shared.BGColor
const Button = Shared.Button
const Image = Shared.Image
const LoadedImage = Shared.LoadedImage

// Layout

const Wrapper = Shared.Wrapper
const Container = Shared.Container
const Grid = Shared.Grid
const Left = Shared.Left
const Right = Shared.Right
const Top = Shared.Top
const Bottom = Shared.Bottom

// Top Layout

const Introduction = Shared.Introduction

// Intro

const Intro = Shared.Intro

// Features

const Features = styled(Shared.Features)`
    ${tw`pb-32`};

    ${Grid} {
        ${tw`flex-no-wrap`};

        ${media.mob`
            ${tw`flex-wrap`};
        `}
    }
`
const Feature = styled(Shared.Feature)`
    ${tw`w-1/4`};

    ${media.mob`
        ${tw`w-1/2`};
        margin-bottom: 24px;
    `}

    ${Description} {
        min-height: none;
        ${tw`mt-8`};
    }
`

// Products

const ProductFeature = styled.div``
const ProductFeatures = styled.div``
const ProductLink = styled(Link)``
const Product = styled.div``
const Products = styled.div`
    ${tw`pb-48 bg-white`};

    ${media.mob`
        ${tw`pb-32`};
    `}

    ${Product} {
        ${boxShadow};

        &:first-child {
            ${tw`relative z-10 -mt-8`};
        }

        &:nth-child(even) {
            ${Right} {
                ${tw`order-first`};

                ${media.mob`
                    order: initial;
                `}
            }
        }

        &:not(:first-child) {
            ${tw`mt-12`};
        }
    }

    ${Grid} {
        ${media.mob`
            ${tw`flex-col`};
        `}
    }

    ${Left}, ${Right} {
        ${tw`py-8 px-6`};
    }

    ${Left} {
        ${tw`w-2/3 bg-darkBlue text-white cursor-pointer`};

        ${media.mob`
            ${tw`w-full`};
        `}

        ${Info} {
            ${tw`flex items-center mb-2`};
            font-weight: 600; /* semibold */
            font-size: 1.375rem;

            ${Image}, ${LoadedImage} {
                ${tw`mr-3`};
            }
        }

        ${Heading}, ${Description} {
            max-width: 400px;
        }

        ${Heading} {
            ${tw`mt-48 text-orange`};
            font-weight: 600; /* semibold */
            font-size: 2.625rem;
            line-height: 1.12;

            ${media.tabPor`
                ${tw`mt-32`};
            `}

            ${media.mob`
                ${tw`mt-8`};
            `}
        }

        ${Grid} {
            ${tw`mt-4 justify-between items-end`};

            ${media.mob`
                ${tw`justify-start items-start`};
            `}
        }

        ${Description}, ${ProductLink} {
            font-weight: 600; /* semibold */
            font-size: 1.25rem;
            line-height: 1.2;
            letter-spacing: -0.02em;
        }

        ${ProductLink} {
            ${tw`text-orange`};
            flex-grow: 0;
            flex-shrink: 0;

            ${media.mob`
                ${tw`mt-4`};
            `}
        }
    }

    ${Right} {
        ${tw`flex flex-col w-1/3 bg-white`};

        ${media.mob`
            ${tw`w-full`};
        `}

        ${ProductFeature} {
            ${tw`flex items-center mb-2 text-orange`};
            font-weight: 500; /* medium */
            font-size: 14px;

            ${Image}, ${LoadedImage} {
                ${tw`mr-2`};
            }
        }

        ${Description} {
            ${tw`mt-auto text-darkBlue`};

            ${media.mob`
                ${tw`mt-4`};
            `}

            font-weight: 400; /* normal */
            font-size: 14px;
            line-height: 1.57;

            strong {
                font-weight: 700; /* bold */
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "business-loans" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Loans
